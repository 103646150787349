@use 'sass:map';
@use '~/sass/vars';
@use "~/sass/core";

.Homepage {
  &__testimonials {
    padding-top: 15px !important;
    padding-bottom: 0px !important;
  }
}
