@use 'sass:map';
@use '~/sass/vars';
@use "~/sass/core";

.CTA {
  width: 100%;
  padding: 40px 32px;
  background: linear-gradient(180deg, #458bd0 0%, #67b2e4 100%);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;

  &.\--blog {
    @media screen and (max-width: 1023px) {
      margin-bottom: 0px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__title {
    text-align: center;
    font-size: 32px !important;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
    margin-bottom: 15px;

    &.\--blog {
      @media screen and (max-width: 1023px) {
        font-size: 24px !important;
        line-height: 30px;
        margin-bottom: 8px;
      }
    }

    &.\--working-with-an-agent {
      @media screen and (max-width: 767px) {
        font-size: 24px !important;
        line-height: 30px;
      }
    }

    &.\--homepage {
      box-shadow: inset 0 -2px rgb(0, 12, 45);
      color: #000c2d;
    }
  }

  &__button {
    display: flex;
    height: 48px;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: #ea580c;
    color: #fff;

    &.\--blog {
      @media screen and (max-width: 1023px) {
        height: 40px !important;
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 100px 32px;
    background: linear-gradient(180deg, #458bd0 0%, #67b2e4 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0px;

    &__title {
      @media screen and (max-width: 767px) {
        font-size: 32px !important;
        line-height: 40px;
      }

      text-align: center;
      font-size: 42px !important;
      font-weight: 700;
      line-height: 54px;
      color: #fff;
      margin-bottom: 25px;
    }
  }

  &__pointer {
    cursor: pointer;
    width: 100%;
  }
}
