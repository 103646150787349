.Card {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  box-shadow: 0px 0px 28px rgba(30, 41, 59, 0.08);
  border-radius: 5px;
  padding: {
    top: 30px;
    bottom: 30px;
    left: 35px;
    right: 35px;
  }
}
