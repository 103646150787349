@use "sass:map";
@use "~/sass/vars";
@use "~/sass/core";
@use "~/sass/atoms/buttons";
@use "~/sass/atoms/typography/headings";
@use "~/sass/atoms/typography/body";

.Testimonial {
  &__content {
    @extend %body;
    line-height: 22px;
    margin-top: 20px;

    overflow: hidden;
    //supported by all by IE
    // https://caniuse.com/?search=line-clamp
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    display: -moz-box;
    transition: max-height 200ms ease-in-out;
    max-height: 7 * 22px;

    &.\--read-more {
      display: block;
      max-height: 100%;
    }

    &.\--left-align {
      text-align: left;
      -webkit-line-clamp: 4;
    }
  }

  &__button-wrapper {
    display: flex;
  }

  &__button {
    &.\--inactive {
      @extend %text-button--arrow-right;
    }

    &.\--active {
      @extend %text-button--arrow-left;
    }

    margin-top: 16px;
  }

  &__top-header {
    @include core.media("min", "753px") {
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.\--row {
      @include core.media("min", "753px") {
        flex-direction: row;
        text-align: left;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__meta-date {
    font-size: 14px;
    line-height: 18px;
  }

  &__info {
    &.\--reverse {
      @include core.media("min", "753px") {
        align-items: normal;
      }

      display: flex;
      align-items: flex-start;
      flex-direction: column-reverse;
    }
  }

  &__info-company {
    @include core.media("min", "753px") {
      margin-bottom: 6px;
    }

    font-size: 14px;
    line-height: 18px;
    color: rgba(map.get(vars.$colors, text-black), 0.8);
    margin-bottom: 3px;
  }

  &__info-name {
    font-size: 20px;
    line-height: 26px;

    &.\--bolder {
      @include core.media("min", "753px") {
        font-size: 20px;
      }

      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &__image {
    @include core.media("min", "753px") {
      margin-right: 20px;
      margin-bottom: 0;
    }
    font-size: 0;
    margin-bottom: 10px;

    img {
      max-width: 60px;
      width: 100%;
      height: auto;
    }
  }

  &__link-wrapper {
    display: flex;
    padding-top: 24px;
  }

  &__link {
    color: map.get(vars.$colors, rea-blue);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  &__initials-circle {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.\--old-component {
      margin-right: 20px;
    }

    &.\--green {
      background-color: #eef5f3;
    }

    &.\--blue {
      background-color: #f0f9ff;
    }

    &.\--eggshell {
      background-color: #fef6ed;
    }

    h3 {
      color: map.get(vars.$colors, text-black);
      font-weight: 400;
      letter-spacing: -3px;
      font-size: 25px;
    }
  }
}
