@use "sass:map";
@use "~/sass/vars";
@use "~/sass/core";
@use "~/sass/atoms/typography/headings";

.Testimonials {
  @include core.media("min", "753px") {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  padding-top: 50px;
  padding-bottom: 50px;

  &__container {
    @extend %width-restrict;
    font-family: "Inter", sans-serif;
    color: map.get(vars.$colors, text-black);
  }

  &__heading {
    @extend %h1;

    @include core.media("min", "753px") {
      margin-bottom: 60px;
    }
    max-width: 625px;
    margin-bottom: 35px;
  }

  &__grid {
    @include core.media("min", "753px") {
      grid-template-columns: repeat(2, 1fr);
    }
    @include core.media("min", "1271px") {
      grid-template-columns: repeat(3, 1fr);
    }

    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    row-gap: 30px;
  }
}
