@use "sass:map";
@use '~/sass/vars';
@use '~/sass/core';

%button {
  @extend %remove-outline;

  @include core.media("min", map.get(vars.$breakpoints, "sm")) {
    font-size: 16px;
    line-height: 28px;
  }

  color: map.get(vars.$colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 14px;
    weight: 600;
  }
  line-height: 28px;

  @include core.media("min", 485px) {
    min-width: 134px;
  }
  appearance: none;
  align-items: center;
  background-color: map.get(vars.$colors, "rea-orange");
  border-radius: 5px;
  color: map.get(vars.$colors, "text-white");
  cursor: pointer;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  margin: {
    top: 20px;
    bottom: 16px;
  }
  min-width: fit-content;
  padding: {
    bottom: 15px;
    left: 30px;
    right: 30px;
    top: 14px;
  }
  text-align: center;
  transition: background-color map.get(vars.$animation, "time")
      map.get(vars.$animation, "ease"),
    color map.get(vars.$animation, "time") map.get(vars.$animation, "ease"),
    border-color map.get(vars.$animation, "time")
      map.get(vars.$animation, "ease");

  &:hover {
    background-color: map.get(vars.$colors, "rea-orange--hover");
  }

  &:active {
    background-color: map.get(vars.$colors, "rea-orange--active");
  }

  &:disabled {
    cursor: not-allowed;
    background-color: map.get(vars.$colors, "disabled");
  }

  &.\--large {
    @include core.media("min", map.get(vars.$breakpoints, "sm")) {
      font-size: 18px;
      line-height: 21.78px;
    }

    font-size: 16px;
    line-height: 19.36px;
  }

  &.\--small,
  small {
    @include core.media("min", map.get(vars.$breakpoints, "sm")) {
      font-size: 14px;
      line-height: 24px;
    }

    font-size: 14px;
    line-height: 28px;
  }
}

%button--secondary {
  @extend %button;

  background-color: map.get(vars.$colors, "rea-blue");
  font-weight: 600;
  padding: {
    bottom: 10px;
    top: 10px;
  }

  &:hover {
    background-color: map.get(vars.$colors, "rea-blue--hover");
  }

  &:active {
    background-color: map.get(vars.$colors, "rea-blue--hover");
  }

  &:disabled {
    background-color: map.get(vars.$colors, "disabled");
  }
}

%button--outline {
  @extend %button;

  color: map.get(vars.$colors, "rea-orange");
  background-color: map.get(vars.$colors, "text-white");
  border: {
    color: map.get(vars.$colors, "rea-orange");
    style: solid;
    width: 1px;
  }
  font-weight: 600;
  padding: {
    bottom: 10px;
    top: 10px;
  }

  &:hover {
    color: map.get(vars.$colors, "rea-orange--hover");
    border-color: map.get(vars.$colors, "rea-orange--hover");
    background-color: map.get(vars.$colors, "text-white");
  }

  &:active {
    color: map.get(vars.$colors, "rea-orange--active");
    border-color: map.get(vars.$colors, "rea-orange--active");
    background-color: map.get(vars.$colors, "text-white");
  }

  &:disabled {
    color: map.get(vars.$colors, "disabled");
    border-color: map.get(vars.$colors, "disabled");
    background-color: map.get(vars.$colors, "text-white");
  }
}

%button--secondary--outline {
  @extend %button--secondary;
  @extend %button--outline;

  color: map.get(vars.$colors, "rea-blue");
  border-color: #94a3b8;
  background-color: map.get(vars.$colors, "text-white");

  &:hover {
    color: map.get(vars.$colors, "rea-blue--hover");
    border-color: map.get(vars.$colors, "rea-blue--hover");
  }

  &:active {
    color: map.get(vars.$colors, "rea-blue--hover");
    border-color: map.get(vars.$colors, "rea-blue--hover");
  }

  &:disabled {
    color: map.get(vars.$colors, "disabled");
    border-color: map.get(vars.$colors, "disabled");
  }
}

%text-button {
  @extend %remove-outline;

  @include core.media("min", map.get(vars.$breakpoints, "sm")) {
    font-size: 16px;
    line-height: 28px;
  }

  color: map.get(vars.$colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 14px;
    weight: 700;
  }
  line-height: 28px;

  color: map.get(vars.$colors, "rea-blue");
  display: inline;
  transition: color map.get(vars.$animation, "time")
    map.get(vars.$animation, "ease");
  cursor: pointer;

  &:hover {
    color: map.get(vars.$colors, "rea-blue--hover");
  }

  &:active {
    color: map.get(vars.$colors, "rea-blue--hover");
  }

  &:disabled {
    color: map.get(vars.$colors, "disabled");
  }

  &.\--large {
    @include core.media("min", map.get(vars.$breakpoints, "sm")) {
      font-size: 18px;
      line-height: 21.78px;
    }

    font-size: 16px;
    line-height: 19.36px;
  }

  &.\--small,
  small {
    @include core.media("min", map.get(vars.$breakpoints, "sm")) {
      font-size: 14px;
      line-height: 24px;
    }

    font-size: 14px;
    line-height: 28px;
  }
}

%text-button--arrow-left {
  @extend %text-button;

  position: relative;
  padding-left: calc(8px + 5.91px);
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 5.91px;
    height: 9.85px;
    background-color: map.get(vars.$colors, "rea-blue");
    mask-image: url("/static/back-button.svg");
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &:hover {
    &::after {
      background-color: map.get(vars.$colors, "rea-blue--hover");
    }
  }

  &:active {
    &::after {
      background-color: map.get(vars.$colors, "rea-blue--hover");
    }
  }

  &:disabled {
    &::after {
      background-color: map.get(vars.$colors, "disabled");
    }
  }
}

%text-button--arrow-right {
  @extend %text-button;

  position: relative;
  padding-right: calc(8px + 5.91px);
  &::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 5.91px;
    height: 9.85px;
    background-color: map.get(vars.$colors, "rea-blue");
    mask-image: url("/static/right-arrow.svg");
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &:hover {
    &::after {
      background-color: map.get(vars.$colors, "rea-blue--hover");
    }
  }

  &:active {
    &::after {
      background-color: map.get(vars.$colors, "rea-blue--hover");
    }
  }

  &:disabled {
    &::after {
      background-color: map.get(vars.$colors, "disabled");
    }
  }
}

%link {
  color: map.get(vars.$colors, rea-orange);
  transition: color map.get(vars.$animation, time)
    map.get(vars.$animation, ease);

  &:hover {
    color: map.get(vars.$colors, rea-orange--hover);
  }

  &:active {
    color: map.get(vars.$colors, rea-orange--active);
  }
}
