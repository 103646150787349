@use "sass:map";
@use '~/sass/vars';
@use '~/sass/core';

%typography-hero-header,
%hero-header {
  @include core.media("min", "1025px") {
    font-size: 60px;
    line-height: 64px;
  }

  color: map.get(vars.$colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 36px;
    weight: 700;
  }
  line-height: 42px;
}

%typography-h1,
%h1 {
  @include core.media("min", "1025px") {
    font-size: 41px;
    line-height: 52px;
  }

  color: map.get(vars.$colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 28px;
    weight: 600;
  }
  line-height: 34px;
}

%typography-h2,
%h2 {
  @include core.media("min", "1025px") {
    font-size: 32px;
    line-height: 40px;
  }

  color: map.get(vars.$colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 24px;
    weight: 600;
  }
  line-height: 29.05px;
}

%typography-h3,
%h3 {
  @include core.media("min", "1025px") {
    font: {
      size: 24px;
      weight: 700;
    }
    line-height: 30px;
  }

  color: map.get(vars.$colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 22px;
    weight: 600;
  }
  line-height: 24px;
}

%typography-h4,
%h4 {
  @include core.media("min", "1025px") {
    font-size: 20px;
    line-height: 24px;
  }

  color: map.get(vars.$colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 18px;
    weight: 600;
  }
  line-height: 24px;
}
